import React from 'react';
import Footer from '../Footer/Footer';
import { EmbedService, BrandId, getBrandIdFromHostname } from '@delta-defense/delta-utils';
import { getStylesByBrand } from '../../utilities/getStylesByBrand';
import { getLogoByBrand } from '../../utilities/getLogoByBrand';

function Layout({ children, embedService = EmbedService.instance() }) {
  const brandId = getBrandIdFromHostname();
  const brandLogo = getLogoByBrand(brandId as BrandId);
  const brandStyleClassName = getStylesByBrand(brandId as BrandId);

  return embedService.isEmbedded ? (
    <div className="fixed-col main pt-3 pt-sm-5 mx-auto">{children}</div>
  ) : (
    <>
      <div className={`fixed-col-${brandStyleClassName} main fascia-${brandId} pt-3 pt-sm-5 mx-auto`}>
        <div className={`${brandStyleClassName}`}>
          {brandLogo}
          <div className="mt-5">{children}</div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Layout;
