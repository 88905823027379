/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { userSignIn, resetTokens, resetError } from '../../redux/modules/tokens';
import { LoadingSpinner } from '../RedirectChecker/RedirectChecker';
import { DefaultErrorPage } from '../ErrorPage/defaultErrorPage';
import InputGroup from '../InputGroup/InputGroup';
import AuthButton from '../AuthButton/AuthButton';
import SignInLinks from '../NavigationLinks/SignInLinks';
import Title from '../Title/Title';
import Question from '../AdditionalInfo/Question';
import AuthAlert from '../AuthAlert/AuthAlert';
import Loading from '../Loading/Loading';
import '../Forms/Forms.scss';
import { BrandId, CookieUtilities, getBrandIdFromHostname } from '@delta-defense/delta-utils';
import { getCheckboxByBrand } from '../../utilities/getCheckboxByBrand';

export class SignInPassword extends Component {
  emailInput: any;
  passwordInput: any;
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (!this.props.token) {
      // @ts-expect-error TS(2339) FIXME: Property 'resetTokens' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.resetTokens();
    } else {
      this.emailInput.focus();
      this.emailInput.click();
    }

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('email')) {
      // @ts-expect-error TS(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.values.email = queryParams.get('email');
      // @ts-expect-error TS(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.setState({ values: this.props.values });
    }
  }

  setCookie = async () => {
    // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { token, ttl } = this.props.token;
    // @ts-expect-error FIXME: prop types broken
    await this.props.cookieUtilities.setAccessToken(token);
  };

  handleCheckboxChange = (e) => {
    // @ts-expect-error TS(2339) FIXME: Property 'setFieldValue' does not exist on type 'R... Remove this comment to see the full error message
    this.props.setFieldValue('remember', e.target.checked);
  };

  postLoginAction = async (
    usccaAccessToken,
    history,
    mainWindow = window,
    setCookieFunc = this.setCookie
  ) => {
    await setCookieFunc();
    history.push(`/redirect${mainWindow.location.search}`);
  };


  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339) FIXME: Property 'errors' does not exist on type 'Readonly... Remove this comment to see the full error message
      errors,
      // @ts-expect-error TS(2339) FIXME: Property 'touched' does not exist on type 'Readonl... Remove this comment to see the full error message
      touched,
      // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
      history,
      // @ts-expect-error TS(2339) FIXME: Property 'statusCode' does not exist on type 'Read... Remove this comment to see the full error message
      statusCode,
      // @ts-expect-error TS(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      values,
      // @ts-expect-error TS(2339) FIXME: Property 'handleKeyUp' does not exist on type 'Rea... Remove this comment to see the full error message
      handleKeyUp,
      // @ts-expect-error TS(2339) FIXME: Property 'resetError' does not exist on type 'Read... Remove this comment to see the full error message
      resetError,
      // @ts-expect-error TS(2339) FIXME: Property 'handleChange' does not exist on type 'Re... Remove this comment to see the full error message
      handleChange,
      // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'Readonly... Remove this comment to see the full error message
      status
    } = this.props;
    // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const usccaAccessToken = this.props.token.token;
    const isVerificationRequest = new URLSearchParams(window.location.search).has('verification');
    const brandId = getBrandIdFromHostname();

    const handledErrorCodes = [404, 422];
    if (statusCode === 200) {
      this.postLoginAction(usccaAccessToken, history);
      return <LoadingSpinner />;
    } else if (!!statusCode && !handledErrorCodes.includes(statusCode)) {
      return <DefaultErrorPage errorStatus={statusCode} />;
    }

    const onRememberMeClicked = () => {
      const checkbox = window.document.getElementById('signInRememberMeCheckBox');
      if (checkbox) {
        checkbox.click();
      }
    };

    return (
      <>
        <Title name={isVerificationRequest ? 'Member Verification' : 'Log In'} />
        {isVerificationRequest && <p className="description">Log in to verify your USCCA membership status</p>}
        <Form>
          <InputGroup
            id="signinEmail"
            name="email"
            label="Email"
            type="email"
            innerRef={(ref) => (this.emailInput = ref)}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            error={errors.email}
            touched={touched.email}
            // @ts-expect-error TS(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
            value={this.props.values.email}
            onChange={handleChange}
            autoComplete="email"
          />
          {!loading && statusCode === 404 && (
            <AuthAlert message="There is no account for the email you entered." />
          )}

          <InputGroup
            id="signinPassword"
            name="password"
            label="Password"
            type="password"
            innerRef={(ref) => (this.passwordInput = ref)}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            error={errors.password}
            touched={touched.password}
            // @ts-expect-error TS(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
            value={this.props.values.password}
            onChange={handleChange}
            autoComplete="current-password"
            passwordUnmasked={status?.passwordUnmasked}
          />
          {!loading && statusCode === 422 && (
            <AuthAlert message="There was an error when entering your password." />
          )}

          <div className="remember-section">
            <div className="form-check">
              {getCheckboxByBrand(brandId as BrandId, {
                values: values,
                handleCheckboxChange: this.handleCheckboxChange,
                onRememberMeClicked: onRememberMeClicked
              })}
            </div>

            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{}' but r... Remove this comment to see the full error message */}
            <SignInLinks />
          </div>

          <AuthButton
            id="signInSignInButton"
            color="btn-gold"
            text={loading ? <Loading /> : 'Log In Now'}
            disabled={loading}
          />
        </Form>

        {brandId === BrandId.USCCA && (
          <Question
            headline="Don't have an account?"
            link={`/register${window.location.search}`}
            text="Sign Up Today"
            id="signUpTodayButton"
          />
        )}
      </>
    );
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SignInPassword.defaultProps = {
  cookieUtilities: CookieUtilities.instance()
};

const SignInPasswordContainer = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    remember: true
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(2, 'Password needs more than 1 characters.')
      .max(40, 'Password needs less than 40 characters.')
      .required('Password is required')
  }),

  validateOnBlur: false,
  handleSubmit: (values, { props, setStatus }) => {
    // this set status is used to pass state from the handleSubmit function to the component
    setStatus({ passwordUnmasked: false });
    const { email, password, remember } = values;
    const yearFromToday = dayjs().add(1, 'year').format('YYYY-MM-DD') + ' 00:00:00';
    const daysFromToday = dayjs().add(7, 'days').format('YYYY-MM-DD') + ' 00:00:00';
    const ttl = remember ? yearFromToday : daysFromToday;
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    props.userSignIn(email, password, ttl);

    // this set status is used to reset the passwordUnmasked state
    setStatus({ passwordUnmasked: undefined });
  },

  displayName: 'SignIn Password'
  // @ts-expect-error FIXME: halp
})(SignInPassword);

const mapStateToProps = ({
  tokens: { token, loading, statusError, statusCode },
  users: { email }
}) => {
  return {
    email,
    token,
    loading,
    statusError,
    statusCode
  };
};

export default connect(mapStateToProps, { userSignIn, resetTokens, resetError })(
  SignInPasswordContainer
);
