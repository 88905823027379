import React from 'react';
import { Field } from 'formik';
import { BrandId } from '@delta-defense/delta-utils';

interface CheckboxProps {
  values: {
    remember: boolean;
  };
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRememberMeClicked: () => void;
}

export const getCheckboxByBrand = (brandId: BrandId, props: CheckboxProps): React.ReactNode => {
  const { values, handleCheckboxChange, onRememberMeClicked } = props;

  switch (brandId) {
    case BrandId.CABELAS:
      return (
        <label
          htmlFor="remember"
          className="form-check-label remember-label"
          onClick={onRememberMeClicked}
        >Remember me
          <Field
            id="remember"
            onChange={handleCheckboxChange}
            checked={values.remember}
            className="form-check-input"
            type="checkbox"
            name="remember"
            label="remember"
          />
          <span className="checkmark"></span>
        </label>
      );
    default:
      return (
        <>
          <Field
            id="remember"
            onChange={handleCheckboxChange}
            checked={values.remember}
            className="form-check-input"
            type="checkbox"
            name="remember"
            label="remember"
          />
          <label
            htmlFor="remember"
            className="form-check-label remember-label"
            onClick={onRememberMeClicked}
          >
         Remember me
            <span className="checkmark"></span>
          </label>
        </>
      );
  }
};
