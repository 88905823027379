import { BrandId } from '@delta-defense/delta-utils';

export const getStylesByBrand = (brandId: BrandId): string => {
  const stylesMap: Record<BrandId, string> = {
    [BrandId.CABELAS]: 'cabelas-style',
    [BrandId.USCCA]: 'uscca-style'
  };

  return stylesMap[brandId] || 'default-style';
};
